import React from 'react';
import './HomepageStats.css'; // Ensure this CSS file contains the styles for mobile and desktop views
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faStethoscope, faBed, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const HomepageStats = () => {
  return (
    <div className="homepage-stats-container">
      {/* Desktop View */}
      <div className="homepage-stats-desktop-view">
        <div className="homepage-stats-item">
          <FontAwesomeIcon icon={faSmile} className="homepage-stats-icon smiley-icon" />
          <h3 className="homepage-stats-number">6930</h3>
          <p className="homepage-stats-label">Happy Patients</p>
        </div>
        <div className="homepage-stats-item">
          <FontAwesomeIcon icon={faStethoscope} className="homepage-stats-icon specialty-icon" />
          <h3 className="homepage-stats-number">390</h3>
          <p className="homepage-stats-label">Medical Specialties</p>
        </div>
        <div className="homepage-stats-item">
          <FontAwesomeIcon icon={faBed} className="homepage-stats-icon room-icon" />
          <h3 className="homepage-stats-number">559</h3>
          <p className="homepage-stats-label">Modern Rooms</p>
        </div>
        <div className="homepage-stats-item">
          <FontAwesomeIcon icon={faCalendarAlt} className="homepage-stats-icon experience-icon" />
          <h3 className="homepage-stats-number">10</h3>
          <p className="homepage-stats-label">Years of Experience</p>
        </div>
      </div>

      {/* Mobile View */}
      <div className="homepage-stats-mobile-view">
        <div className="homepage-stats-item">
          <FontAwesomeIcon icon={faSmile} className="homepage-stats-icon smiley-icon" />
          <h3 className="homepage-stats-number">6930</h3>
          <p className="homepage-stats-label">Happy Patients</p>
        </div>
        <div className="homepage-stats-item">
          <FontAwesomeIcon icon={faBed} className="homepage-stats-icon room-icon" />
          <h3 className="homepage-stats-number">559</h3>
          <p className="homepage-stats-label">Modern Rooms</p>
        </div>
        <div className="homepage-stats-item">
          <FontAwesomeIcon icon={faStethoscope} className="homepage-stats-icon specialty-icon" />
          <h3 className="homepage-stats-number">390</h3>
          <p className="homepage-stats-label">Medical Specialties</p>
        </div>
        <div className="homepage-stats-item">
          <FontAwesomeIcon icon={faCalendarAlt} className="homepage-stats-icon experience-icon" />
          <h3 className="homepage-stats-number">10</h3>
          <p className="homepage-stats-label">Years of Experience</p>
        </div>
      </div>
    </div>
  );
};

export default HomepageStats;
