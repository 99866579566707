import React from 'react';
import './HomeTestimonials.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import man from '../../media/man.png'

const testimonials = [
  {
    image: {man},
    name: 'Ravi Kumar',
    description: 'Dr. Maurya’s treatment for my piles issue was effective and quick. I felt comfortable throughout the process.',
  },
  {
    image: {man},
    name: 'Anita Sharma',
    description: 'Excellent care and professional approach. Dr. Maurya is highly skilled and made me feel at ease during the treatment.',
  },
  {
    image: {man},
    name: 'Pooja Verma',
    description: 'I had been suffering from fissures for a long time, but thanks to Dr. Maurya, I’m finally relieved. Highly recommended!',
  },
  {
    image: {man},
    name: 'Rahul Singh',
    description: 'The best anorectal specialist in Lucknow! I’m grateful for the professional and empathetic care I received.',
  },
  {
    image: {man},
    name: 'Vikram Mehra',
    description: 'Top-notch treatment and excellent care. Dr. Maurya is highly experienced, and it shows in his patient care.',
  },
];

const HomeTestimonials = () => {
  return (
    <div className="home-testimonials">
      <h2 className="testimonials-heading">Patient Testimonials</h2>
      <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img src={man} alt={`${testimonial.name}'s testimonial`} className="testimonial-image" />
            <div className="testimonial-stars">
              {'★'.repeat(5)} {/* 5 golden stars */}
            </div>
            <h4 className="testimonial-name">{testimonial.name}</h4>
            <p className="testimonial-description">{testimonial.description}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeTestimonials;
