import React from 'react';
import './Homepage.css';
import heroWide from '../../media/hero_wide.jpg';
import heroMobile from '../../media/hero_mobile.jpg';
import Services from '../Services/Services.jsx';
import HomeAbout from '../HomeAbout/HomeAbout.jsx';
import ConditionsTreated from '../ConditionsTreated/ConditionsTreated.jsx';
import HomeTestimonials from '../HomeTestimonials/HomeTestimonials.jsx';
import HomepageStats from '../HomepageStats/HomepageStats.jsx';
import TreatmentSection from '../TreatmentSection/TreatmentSection.jsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProcedures, faBandAid, faSyringe, faUserMd } from "@fortawesome/free-solid-svg-icons";
import HeroSection from './HeroSection.jsx';

const Homepage = () => {
  const handleWhatsAppRedirect = () => {
    // Redirect to WhatsApp chat with a default message
    window.location.href = 'https://wa.me/+919565350507';
  };



  const conditions = [
    {
      id: 1,
      icon: faProcedures,
      title: "Piles (Hemorrhoids)",
      description: "Swollen veins in the rectal area, causing discomfort and bleeding during bowel movements.",
      symptoms: [
        "Pain and swelling around the anus",
        "Itching or irritation in the anal region",
        "Bleeding during bowel movements",
      ],
      image: 'path/to/piles-image.jpg',
    },
    {
      id: 2,
      icon: faBandAid,
      title: "Fissures",
      description: "A small tear in the lining of the anus, often from hard stools, causing severe pain and bleeding.",
      symptoms: [
        "Sharp pain during and after bowel movements",
        "Bright red blood on toilet paper",
        "A visible tear in the skin around the anus",
      ],
      image: 'path/to/fissure-image.jpg',
    },
    {
      id: 3,
      icon: faSyringe,
      title: "Pilonidal Sinus",
      description: "A small hole or cavity near the tailbone that can become infected, often containing hair and skin debris.",
      symptoms: [
        "Pain and swelling at the base of the spine",
        "Drainage of pus or blood",
        "Recurring infections and abscesses",
      ],
      image: 'path/to/pilonidal-image.jpg',
    },
    {
      id: 4,
      icon: faUserMd,
      title: "Fistulas",
      description: "A small tunnel forming between the bowel end and skin near the anus, often developing after an abscess.",
      symptoms: [
        "Pain and swelling around the anus",
        "Discharge of pus or blood",
        "Recurring infections",
      ],
      image: 'path/to/fistula-image.jpg',
    },
  ];


  return (
    <>

<HeroSection/>



      <div
        className="homepage-container"
        style={{
          backgroundImage: `url(${window.innerWidth <= 768 ? heroMobile : heroWide})`,
        }}
      >


        <div className="homepage-overlay">
          <div className="homepage-content">
            <h1 className="homepage-heading">Welcome to Dr. Sushil Maurya's Clinic</h1>
            <ul className="homepage-pointers">
              <li>→ Comprehensive health services for you and your family</li>
              <li>→ Personalized medical consultation</li>
              <li>→ Expert advice from highly qualified professionals</li>
              <li>→ State-of-the-art facilities for diagnostics and treatment</li>
            </ul>
            <button className="home-about-appointment-button"  onClick={handleWhatsAppRedirect}>Book an Appointment Now</button>
          </div>
        </div>
      </div>
      <Services/>
      <HomeAbout/>
      <ConditionsTreated/>
      <HomepageStats/>
      <TreatmentSection/>
      <HomeTestimonials/>


    </>
  );
};

export default Homepage;
