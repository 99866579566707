import React from 'react';
import { useParams } from 'react-router-dom';
import './ServiceDetail.css';

import fissure from '../../media/fiss.png'
import hams from '../../media/hamss.png'
import fist from '../../media/fist.png'

const serviceData = {
    fissure: {
        banner: fissure,
        title: 'Fissure Treatment',
        subtitle: 'Gentle and Effective Care for Anal Fissures',
        description: 'Anal fissures can be incredibly painful and disrupt your daily life. Our dedicated team understands the discomfort you are facing, and we are here to provide compassionate, expert care that promotes healing and relieves pain.',
        details: 'Our treatment for fissures combines the latest in medical advancements with a personal touch. We offer laser therapy that ensures precision and quick recovery, medications to ease pain, and guidance on lifestyle changes that can help prevent future occurrences. Every treatment plan is tailored to your specific condition, ensuring you get the care you need to heal comfortably.',
        content: 'Anal fissures are small tears in the lining of the anus that can cause severe pain during bowel movements. Our approach focuses on addressing the root cause, managing symptoms, and guiding you through every step of your recovery.'
    },
    fistula: {
        banner: fist, // replace with actual paths
        title: 'Fistula Treatment',
        subtitle: 'Precise and Personalized Solutions for Fistula Conditions',
        description: 'A fistula can be a complex condition, but it is manageable with the right treatment. At our clinic, we provide expert care that is both effective and considerate, ensuring that you receive the best possible outcome.',
        details: 'We utilize a range of advanced techniques, including minimally invasive surgical options, to treat fistulas. Our goal is to address the issue at its core, reducing symptoms and preventing recurrence. Every patient receives a personalized treatment plan, with options ranging from cutting-edge surgical procedures to non-invasive methods, ensuring a smooth and safe recovery.',
        content: 'Fistulas are abnormal connections that can develop between two parts of the body, often leading to discomfort and other complications. Our expert team offers solutions that are effective and focus on long-term recovery and well-being.'
    },
    piles: {
        banner: hams, // replace with actual paths
        title: 'Piles Treatment',
        subtitle: 'Advanced Care for Hemorrhoids with a Focus on Comfort',
        description: 'Piles, commonly known as hemorrhoids, can cause discomfort and disrupt your daily routine. Our clinic provides modern, pain-relieving treatments designed to ease symptoms and improve your quality of life.',
        details: 'We offer both surgical and non-surgical treatments for piles, including laser procedures that are quick, precise, and minimize recovery time. Our approach focuses on understanding your symptoms, lifestyle, and concerns, allowing us to recommend a treatment plan that suits you best. Whether you are dealing with internal or external piles, we are here to provide compassionate, effective care.',
        content: 'Hemorrhoids can be uncomfortable and even debilitating, but they are treatable. Our team is dedicated to offering personalized care that targets the source of your discomfort, providing relief and helping you regain comfort in your day-to-day life.'
    }
};


const ServiceDetail = () => {
    const { serviceName } = useParams();
    const service = serviceData[serviceName.toLowerCase()];
    const handleWhatsAppRedirect = () => {
        // Redirect to WhatsApp chat with a default message
        window.location.href = 'https://wa.me/+919565350507';
      };
    
    

    if (!service) {
        return <h2>Service not found</h2>;
    }



    return (
        <div className="service-detail-container">
            <img src={service.banner} alt={service.title} className="service-detail-banner" />
            <div className="service-detail-content">
                <h2 className="service-detail-title">{service.title}</h2>
                <h4 className="service-detail-subtitle">{service.subtitle}</h4>
                <p className="service-detail-description">{service.description}</p>
                <div className="service-detail-details">
                    <h3>What We Offer:</h3>
                    <p>{service.details}</p>
                </div>
                <div className="service-detail-more-info">
                    <h3>Additional Information:</h3>
                    <p>{service.content}</p>
                </div>
            </div>
            <button className="home-about-appointment-button"  onClick={handleWhatsAppRedirect}>Book an Appointment Now</button>
        </div>
    );
};

export default ServiceDetail;
