import React from 'react'
import MapComponent from '../AboutUs/MapAbout'
import doctorImage from '../../media/file.png';
import './ContactUs.css'

export default function ContactUs() {

    const handleWhatsAppRedirect = () => {
        // Redirect to WhatsApp chat with a default message
        window.location.href = 'https://wa.me/+919565350507';
      };
    
      
      
  return (
    <div className='about-us-home-container'>
        <div className="about-us-container">
    <div className="about-us-header">
        <img src={doctorImage} alt="Dr. Sushil Maurya" className="about-us-doctor-image" />
        <h3 className="about-us-doctor-name">Dr. Sushil Maurya</h3>
    </div>

    <div className="about-us-cards">
        <div className="about-us-card">
            <h4 className="about-us-clinic-title">Clinic 1</h4>
            <p className="about-us-clinic-address">
                Hindustan Piles Hospital, Aviral Tower, Matiyari Chinhut, Lucknow 226028
            </p>
            <p>support@drsushimaurya.org</p>
            <p>+91 9565350507</p>
            <button className="about-us-appointment-button" onClick={handleWhatsAppRedirect}>Book an Appointment</button>
        </div>

        <div className="about-us-card">
            <h4 className="about-us-clinic-title">Clinic 2</h4>
            <p className="about-us-clinic-address">
            Maxveda piles hospital, Sohawal chauraha tahseel road ayodhya 22602
            </p>
            <p>support@drsushimaurya.org</p>
            <p>+91 9451797824</p>
            <button className="about-us-appointment-button" onClick={handleWhatsAppRedirect}>Book an Appointment</button>
        </div>
    </div>
</div>

        <MapComponent/>
      
    </div>
  )
}
