import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../../media/logo-trans.png'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false); // Close the menu after navigation
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo" onClick={() => handleNavigation('/')}>
        <img src={logo} alt="Logo" className="logo" />
        {/* <h1 className="website-name">Dr. Sushil Maurya</h1> */}
      </div>

      {/* Hamburger Menu Icon */}
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      {/* Regular navbar links */}
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li onClick={() => handleNavigation('/')}>Home</li>
        <li onClick={() => handleNavigation('/about-us')}>About</li>
        <li onClick={() => handleNavigation('/services')}>Services</li>
        <li onClick={() => handleNavigation('/contact')}>Contact</li>
        <li onClick={() => handleNavigation('/gallary')}>Gallary</li>
      </ul>
    </nav>
  );
};

export default Navbar;
