import React, { useState } from "react";
import "./Gallery.css"; // Importing the CSS file for styling

const handleWhatsAppRedirect = () => {
  // Redirect to WhatsApp chat with a default message
  window.location.href = 'https://wa.me/+919565350507';
};


const Gallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  

  return (
    <div className="conditions-treated">
        <button className="home-about-appointment-button"  onClick={handleWhatsAppRedirect}>Book an Appointment Now</button>

      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            {/* Add the gallery-thumbnail-wrapper to enforce consistent size */}
            <div className="gallery-thumbnail-wrapper">
              <img
                src={image}
                alt={`Gallery image ${index + 1}`}
                onClick={() => openImage(image)}
                className="gallery-thumbnail"
              />
            </div>
          </div>
          
        ))}
        
      </div>
      <button className="home-about-appointment-button"  onClick={handleWhatsAppRedirect}>Book an Appointment Now</button>

      {selectedImage && (
        <div className="overlay" onClick={closeImage}>
          <div className="overlay-content">
            <img src={selectedImage} alt="Selected" className="popup-image" />
            <span className="close-btn" onClick={closeImage}>
              &times;
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
