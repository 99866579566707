import React from 'react';
import './HomeAbout.css';
import doctorImage from '../../media/file.png'; // Replace with the actual path to the doctor's image

const HomeAbout = () => {
    const handleWhatsAppRedirect = () => {
        // Redirect to WhatsApp chat with a default message
        window.location.href = 'https://wa.me/+919565350507';
      };
    

    return (
        <section className="home-about">
            <div className="home-about-content">
                <h2 className="home-about-heading">Dr. Sushil Maurya - Your Trusted Anorectal Specialist in Lucknow</h2>
                <div className="home-about-body">
                    <div className="home-about-text">
                        <p>
                            If you are looking for compassionate, effective care for anorectal conditions, look no further than Dr. Sushil Maurya. With years of experience in treating piles, fissures, pilonidal sinus, and fistulas, Dr. Maurya combines modern laser techniques with traditional Ksharsutra therapies to offer personalized treatment that suits each patient's specific needs. His patient-centered approach ensures that every visit feels comfortable and informed, with a focus on long-term relief and well-being. Discover why so many patients across Lucknow trust Dr. Maurya for their anorectal care – because when it comes to your health, you deserve the best.
                        </p>
                    </div>
                    <div className="home-about-image-box">
                        <img src={doctorImage} alt="Dr. Sushil Maurya" className="home-about-doctor-image" />
                        <h3 className="home-about-doctor-name">Dr. Sushil Maurya</h3>
                    </div>
                </div>
                <button className="home-about-appointment-button"  onClick={handleWhatsAppRedirect}>Book an Appointment Now</button>
            </div>
        </section>
    );
};

export default HomeAbout;
