import React from 'react';
import './Footer.css';
// import logo from '../../media/adv.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a onClick={() => handleNavigate('/')}>Home</a></li>
                        <li><a onClick={() => handleNavigate('/about-us')}>About</a></li>
                        <li><a onClick={() => handleNavigate('/services')}>Services</a></li>
                        <li><a onClick={() => handleNavigate('/contact')}>Contact</a></li>
                        <li><a onClick={() => handleNavigate('/gallary')}>Gallary</a></li>
                    </ul>

                </div>
                <div className="footer-section">
                    <h4>Our Services</h4>
                    <ul>
                        <li><a onClick={() => handleNavigate('/fissure')}>Fissure Treatment</a></li>
                        <li><a onClick={() => handleNavigate('/fistula')}>Fistula Treatment</a></li>
                        <li><a onClick={() => handleNavigate('/piles')}>Piles Treatment</a></li>
                    </ul>
                </div>
                {/* <div className="footer-section">
                    <h4>Legal</h4>
                    <ul>
                        <li><a onClick={() => handleNavigate('/advance-techno-services-terms-condition')}>Terms & Condition</a></li>
                        <li><a onClick={() => handleNavigate('/advance-techno-policies')}>Refund Policy</a></li>
                        <li><a onClick={() => handleNavigate('/advance-techno-privacy-policy')}>Privacy Policy</a></li>
                    </ul>
                </div> */}
                <div className="footer-section">
                    <address>
                    Maxveda piles hospital
                        <br />Sohawal chauraha tahseel road ayodhya 22602<br />
                        <a href="mailto:info@advancetechno.org">support@drsushimaurya.org</a><br />
                        <a href="tel:+917976289796">+91 9451797824</a>
                    </address>

                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-social">
                    <a href="https://www.facebook.com/share/TyqBES3yb7LAG753/" aria-label="Facebook"><FontAwesomeIcon icon={faFacebook} /></a>
                    {/* <a href="https://www.linkedin.com/company/advance-techno-digital-marketing-agency/" aria-label="Linkedin"><FontAwesomeIcon icon={faLinkedinIn} /></a> */}
                    <a href="https://www.instagram.com/drsushilmaurya?utm_source=qr" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
                </div>
            </div>
            <div className="footer-legal">
                <p>
                    Copyright Inc. All rights reserved.
                    <a onClick={() => handleNavigate('/advance-techno-services-terms-condition')}> Terms of Use</a> | <a onClick={() => handleNavigate('/advance-techno-privacy-policy')}>Privacy Policy</a>
                </p>
                <p>
                    support@drsushimaurya.org | TTY: +91 95653 50507 | +91 94517 97824
                </p>
            </div>
        </footer>
    );
};

export default Footer;