import React from 'react';
import './AboutUs.css';
import doctorImage from '../../media/file.png';


const AboutUs = () => {

    const handleWhatsAppRedirect = () => {
        // Redirect to WhatsApp chat with a default message
        window.location.href = 'https://wa.me/+919565350507';
      };
    

      
    return (
        <div className="about-us-container">
            <h1 className="about-us-main-heading">About Our Doctor</h1>
            <div className="about-us-content">
                <div className="about-us-left-section">
                    <h2>About - Dr. Sushil Maurya</h2>
                    <p>
                        Dr. Sushil Maurya, BAMS, is a distinguished Consultant Physician & Anorectal Surgeon, 
                        well-regarded for his expertise in managing a wide range of anorectal conditions, including 
                        piles, fissures, pilonidal sinus, and fistulas. Practicing in Lucknow, Dr. Maurya combines a 
                        deep understanding of traditional and modern treatment methods, offering both laser therapies 
                        and the time-tested Ksharsutra approach to provide comprehensive care tailored to each patient’s 
                        unique needs.
                    </p>
                    <p>
                        With over a decade of experience, Dr. Maurya has treated countless patients, helping them regain 
                        comfort and confidence. His compassionate approach sets him apart, as he takes the time to understand 
                        every patient’s concerns, providing personalized treatment plans that focus not only on alleviating 
                        symptoms but also on improving overall well-being. Whether it’s a quick consultation or a more intensive 
                        treatment, Dr. Maurya ensures that his patients receive the utmost care and attention.
                    </p>
                    <p>
                        Dr. Maurya’s commitment to excellence extends beyond the clinic. He stays updated with the latest 
                        advancements in anorectal treatments, ensuring that his patients benefit from the most effective 
                        and minimally invasive procedures available. Known for his gentle, reassuring manner, he has built 
                        a strong reputation across Lucknow as a doctor who truly cares. For anyone suffering from anorectal 
                        issues, Dr. Sushil Maurya is not just a specialist – he is a trusted ally on the path to better health.
                    </p>
                </div>
                <div className="about-us-right-section">
                    <img src={doctorImage} alt="Dr. Sushil Maurya" className="about-us-doctor-image" />
                    <h3 className="about-us-doctor-name">Dr. Sushil Maurya</h3>
                    <ul className="about-us-locations">
                    <li><strong>Clinic 1:</strong>  Hindustan Piles Hospital, Aviral tower near hanuman mandir matiyari chinahut,Lucknow 226028</li>
                    <li><strong>Clinic 2:</strong>  Hindustan Piles Hospital, Sohawal Chauraha Tahsil Road Ayodhya</li>
                    </ul>
                    <button className="homepage-book-button" onClick={handleWhatsAppRedirect}>
              Book Appointment
            </button>
                </div>
            </div>

            {/* Mission and Vision Section */}
            <div className="about-us-mission-vision">
                <h2 className="about-us-mission-vision-heading">Mission & Vision</h2>
                <h3 className="about-us-mission-heading">Our Mission</h3>
                <p className="about-us-mission-text">
                    Our mission is to provide compassionate, high-quality healthcare that enhances the well-being of our patients. 
                    Dr. Sushil Maurya is dedicated to treating patients with respect and dignity, ensuring that each individual 
                    receives personalized care tailored to their unique needs. We strive to educate our patients about their 
                    conditions and empower them to make informed decisions regarding their health.
                </p>
                <h3 className="about-us-vision-heading">Our Vision</h3>
                <p className="about-us-vision-text">
                    Our vision is to be a leader in anorectal healthcare, recognized for our innovative treatment methods and 
                    exceptional patient outcomes. We aim to create a supportive environment where patients feel valued and 
                    cared for. By staying at the forefront of medical advancements, we aspire to provide the most effective 
                    treatments that improve our patients' quality of life.
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
