import React from 'react';
import './Services.css';
import Piles from '../../media/Piles.webp';
import Fistula from '../../media/Fistula.webp';
import Fissure from '../../media/Fissure.webp';

const Services = () => {
    const handleCardClick = (serviceName) => {
        // Navigate to the specific service detail page
        window.location.href = `/${serviceName}`;
    };

    return (
        <div className="services-container">
            <h2 className="services-heading">Services and Our Expertise</h2>
            <div className="services-cards">
                <div className="service-card" onClick={() => handleCardClick('fissure')}>
                    <img src={Fissure} alt="Fissure" className="card-image" />
                    <h4 className="card-title">Fissure Treatment</h4>
                    <p className="card-text">Learn more about our Fissure treatment service.</p>
                </div>
                <div className="service-card" onClick={() => handleCardClick('fistula')}>
                    <img src={Fistula} alt="Fistula" className="card-image" />
                    <h4 className="card-title">Fistula Treatment</h4>
                    <p className="card-text">Discover how we can help with Fistula treatment.</p>
                </div>
                <div className="service-card" onClick={() => handleCardClick('piles')}>
                    <img src={Piles} alt="Piles" className="card-image" />
                    <h4 className="card-title">Piles Treatment</h4>
                    <p className="card-text">Get to know more about our Piles treatment service.</p>
                </div>
            </div>
        </div>
    );
};

export default Services;
