import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProcedures, faBandAid, faSyringe, faUserMd } from "@fortawesome/free-solid-svg-icons";
import './ConditionsTreated.css';

const ConditionsTreated = () => {
  return (
    <div className="conditions-treated">
      <h2 className="conditions-heading">Conditions Treated</h2>
      <div className="conditions-container">
        <div className="condition-item">
          <FontAwesomeIcon icon={faProcedures} className="condition-icon" />
          <h3>Piles (Hemorrhoids)</h3>
          <p>
            Piles are swollen veins in the rectal area, causing discomfort and bleeding during bowel movements. They can be internal (inside the rectum) or external (around the anal opening).
          </p>
          <strong>Symptoms:</strong>
          <ul>
            <li>Pain and swelling around the anus</li>
            <li>Itching or irritation in the anal region</li>
            <li>Bleeding during bowel movements</li>
          </ul>
        </div>

        <div className="condition-item">
          <FontAwesomeIcon icon={faBandAid} className="condition-icon" />
          <h3>Fissures</h3>
          <p>
            An anal fissure is a small tear in the lining of the anus, often resulting from passing hard stools. It can cause severe pain and bleeding.
          </p>
          <strong>Symptoms:</strong>
          <ul>
            <li>Sharp pain during and after bowel movements</li>
            <li>Bright red blood on toilet paper</li>
            <li>A visible crack or tear in the skin around the anus</li>
          </ul>
        </div>

        <div className="condition-item">
          <FontAwesomeIcon icon={faSyringe} className="condition-icon" />
          <h3>Pilonidal Sinus</h3>
          <p>
            This is a small hole or cavity in the skin that can become infected, usually located near the tailbone. It often contains hair and skin debris.
          </p>
          <strong>Symptoms:</strong>
          <ul>
            <li>Pain and swelling at the base of the spine</li>
            <li>Drainage of pus or blood from the area</li>
            <li>Recurring infections and abscesses</li>
          </ul>
        </div>

        <div className="condition-item">
          <FontAwesomeIcon icon={faUserMd} className="condition-icon" />
          <h3>Fistulas</h3>
          <p>
            An anal fistula is a small tunnel that forms between the end of the bowel and the skin near the anus. It usually develops after an abscess.
          </p>
          <strong>Symptoms:</strong>
          <ul>
            <li>Pain and swelling around the anus</li>
            <li>Discharge of pus or blood</li>
            <li>Recurring infections</li>
          </ul>
        </div>
      </div>

      {/* Symptoms & Treatment Options Section */}

    </div>
  );
};

export default ConditionsTreated;
