import React from 'react';

const MapComponent = () => {
  return (
    <div style={{ width: '100%', height: '400px', position: 'relative' }}>
      <iframe
        title="Google Map"
        width="100%"
        height="100%"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://maps.google.com/maps?q=587+155th+Pl+NE,+Bellevue,+WA+98007,+USA&output=embed"
        style={{ border: 0 }}
      ></iframe>
    </div>
  );
};

export default MapComponent;