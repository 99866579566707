import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import heroWide1 from '../../media/hero_wide.jpg'; // Make sure to use the correct images
import heroWide2 from '../../media/pilt.jpg';
import heroWide3 from '../../media/hero_wide.jpg';

const HeroSection = () => {
    // Array of content and images to cycle through
    const contentList = [
        {
            title: "Welcome to Dr. Sushil Maurya's Clinic",
            subtitle: "Comprehensive health services for you and your family",
            description: [
                "→ Personalized care",
                "→ Expert guidance",
                "→ Modern facilities",
            ],
            image: heroWide1
        },
        {
            title: "Dr. Sushil Maurya: Specialized in Piles Treatment",
            subtitle: "Relief from Discomfort & Pain",
            description: [
                "→ Hemorrhoid relief",
                "→ Advanced options",
                "→ Lasting comfort"
            ],
            image: heroWide2
        },
        {
            title: "Dr. Sushil Maurya: Specialized in Fissure Treatment",
            subtitle: "Healing with Minimal Discomfort",
            description: [
                "→ Pain relief",
                "→ Specialized care",
                "→ Non-surgical options"
            ],
            image: heroWide2
        },
        {
            title: "Dr. Sushil Maurya: Specialized in Fistula Treatment",
            subtitle: "Comprehensive Care for Complicated Conditions",
            description: [
                "→ Tailored care",
                "→ Advanced treatment",
                "→ Quality support"
            ],
            image: heroWide3
        },
        {
            title: "Dr. Sushil Maurya: Specialized in Pilonidal Sinus Treatment",
            subtitle: "Effective Solutions for Lasting Relief",
            description: [
                "→ Cyst management",
                "→ Effective treatment",
                "→ Surgical options"
            ],
            image: heroWide1
        }
    ];
    

    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeState, setFadeState] = useState("fade-in");

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFadeState("fade-out"); // Start fade-out animation

            setTimeout(() => {
                // After fade-out, update the index and start fade-in animation
                setCurrentIndex((prevIndex) => (prevIndex + 1) % contentList.length);
                setFadeState("fade-in");
            }, 1000); // Delay to match fade-out animation duration
        }, 5000); // Update content every 5 seconds

        return () => clearInterval(intervalId);
    }, []);

    const { title, subtitle, description, image } = contentList[currentIndex];

    return (
        <div className="hero-section" style={{ backgroundImage: `url(${image})` }}>
            <div className="hero-section__container">
                <div className={`hero-section__left-content ${fadeState}`}>
                    <h1 className="hero-section__title">{title}</h1>
                    <h2 className="hero-section__subtitle">{subtitle}</h2>
                    <p className="hero-section__description">
                        {description.map((item, index) => (
                            <span key={index}>{item}<br /></span>
                        ))}
                    </p>
                </div>
                <div className={`hero-section__right-content ${fadeState}`}>
                    <div className="hero-section__image-container">
                        <img src={image} alt="Clinic Service" className="hero-section__image" />
                    </div>
                    <div className="hero-section__call-to-action">
                        <button className="hero-section__apply-button">Book Now ➜</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
