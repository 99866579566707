import React from 'react';
import './TreatmentSection.css';



const TreatmentSection = () => {

  const handleWhatsAppRedirect = () => {
    // Redirect to WhatsApp chat with a default message
    window.location.href = 'https://wa.me/+919565350507';
  };


  const treatments = [
    {
      title: 'Piles',
      description: 'Piles, also known as hemorrhoids, are common conditions where the hemorrhoids become inflamed.',
      image: 'https://www.freefrompiles.com/wp-content/uploads/2021/08/piles.svg',
    },
    {
      title: 'Fissure',
      description: 'A fissure occurs when the inner lining of the anal passage gets torn due to traumatic or non-traumatic reasons.',
      image: 'https://www.freefrompiles.com/wp-content/uploads/2021/08/Fissure.svg',
    },
    {
      title: 'Fistula',
      description: 'A fistula is a condition where there is an abnormal connection between two unrelated parts of the body.',
      image: 'https://www.freefrompiles.com/wp-content/uploads/2021/08/fistula.svg',
    },
    {
      title: 'Pilonidal Sinus',
      description: 'A tube-like formation that occurs at the tailbone or natal cleft region, wherein a cyst is formed.',
      image: 'https://www.freefrompiles.com/wp-content/uploads/2021/08/Pilonidal-Sinus.svg',
    },
  ];

  const renderTreatmentCards = () => {
    return treatments.map((treatment, index) => (
      <div key={index} className="home-page-owl-carousel-single-features">
        <div className="home-page-owl-carousel-single-icon">
          <img src={treatment.image} alt={treatment.title} />
        </div>
        <h3>{treatment.title}</h3>
        <p>{treatment.description}</p>
        <button className="home-about-appointment-button"  onClick={handleWhatsAppRedirect}>Book an Appointment Now</button>

      </div>
    ));
  };

  return (
    <section className="home-page-owl-carousel-features section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>We Provide Wide Range Of Treatment With Proper Care</h2>
              <p>We offer various treatments such as Piles, Fissure, Fistula, and Pilonidal Sinus, with proper care and expertise.</p>
            </div>
          </div>
        </div>
        <div className="row treatment-grid">
          {renderTreatmentCards()}
        </div>
      </div>
    </section>
  );
};

export default TreatmentSection;
