import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Homepage from './components/Homepage/Homepage';
import Navbar from './components/Navbar/Navbar';
import ServiceDetail from './components/ServiceDetail/ServiceDetail';
import AboutUs from './components/AboutUs/AboutUs';
import Footer from './components/Footer/Footer';
import Services from './components/Services/Services';
import ContactUs from './components/ContactUs/ContactUs';
import Gallery from './components/Gallery/Gallery';
import g1 from '../src/media/g1.jpeg'
import g2 from '../src/media/g2.jpeg'
import g3 from '../src/media/g3.jpeg'
import g4 from '../src/media/g4.jpeg'
import g5 from '../src/media/g5.jpeg'
import g6 from '../src/media/g6.jpeg'


// Custom hook to scroll to top on route change
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const images = [
  g1, g2,g3,g4,g5,g6,
  g1, g2,g3,g4,g5,g6,
  g1, g2,g3,g4,g5,g6,
  // Add more image URLs here
];

export default function App() {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/:serviceName" element={<ServiceDetail />} /> {/* Use 'element' prop here */}
          <Route path="/about-us" element={<AboutUs />} /> {/* Use 'element' prop here */}
          <Route path="/contact" element={<ContactUs />} /> {/* Use 'element' prop here */}
          <Route path="/gallary" element={<Gallery images={images}/>} /> {/* Use 'element' prop here */}


        </Routes>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}
